<template>
  <div class="remove-container">
    <image-resizer modelName="imageResizer" />
  </div>
</template>

<script setup>
import ImageResizer from "@/components/models/imageResizer.vue";
import { useMeta } from 'vue-meta'
useMeta({
  title: 'Resize images as you desire - Boolv.Toolkit',
  description: "Resize JPG,WEBP, PNG, GIF, SVG, or BMP images online for free now. You can use our Image Resizer tool to make it ideal for printing, publishing to social media, and other uses.",
  htmlAttrs: { lang: 'en', amp: true }
})
</script>
<style lang="less">
.remove-container {
  width: 100%;
  height: 100%;
}
</style>
